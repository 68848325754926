import { FormControl, Grid, MenuItem, Select, Theme, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import isAlpha from 'validator/lib/isAlpha';
import isEmail from 'validator/lib/isEmail';
import isEmpty from 'validator/lib/isEmpty';
import isMobilePhone from 'validator/lib/isMobilePhone';
import { CAPTIONS_TEXT_COLOR } from '../../colors';
import { isUrlPresent } from '../../utils/validators';
import { StyledSelectElement, StyledSelectOptionElement } from '../inputs/select-dropdwon';
import { CustomTextArea } from '../inputs/text-area';
import { CustomInput } from '../inputs/text-field';
import { ErrorMessage } from '../primitives/error-message';
import { PrimaryButton } from '../primitives/primary-button';

const initialValues = {
  fullName: '',
  email: '',
  phone: '',
  position: '',
  enquiryType: '',
  message: ''
};

export interface ContactAgentFormValues {
  fullName: string;
  email: string;
  phone: string;
  position: string;
  enquiryType: string;
  message?: string;
}

export interface ContactAgentFormProps {
  theme: Theme;
}

export default function ContactAgentForm(props: ContactAgentFormProps) {
  const { theme } = props;
  const [formValues, setFormValues] = useState<ContactAgentFormValues>(initialValues);
  const [errorValues, setErrorValues] = useState<ContactAgentFormValues>(initialValues);
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { fullName, email, enquiryType, message, phone, position } = formValues;

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    let result = validateForm(formValues);
    const { isValidForm, errorValues } = result;
    if (!isValidForm) {
      setErrorValues(errorValues);
    } else {
      console.log('Valid');
    }
    setIsSubmitting(false);
  };

  const settingFormValue = (obj: { value: string; field: string }) => {
    const { value, field } = obj;
    setFormValues((formValues) => ({ ...formValues, [field]: value }));
  };

  const validateForm = (formValues: ContactAgentFormValues) => {
    let isValid = true;
    let error = { ...errorValues };
    Object.keys(formValues).map((key) => {
      let value = formValues[key];

      if (key != 'message' && isEmpty(value)) {
        error[key] = '*Mandatory field';
        isValid = false;
      } else if (isUrlPresent(value)) {
        error[key] = '*URL not allowed';
        isValid = false;
      } else {
        error[key] = '';
      }
    });
    console.log({ isValidForm: isValid, errorValues: error });
    if (!isValid) {
      setIsValidForm(isValid);
      return { isValidForm: isValid, errorValues: error };
    }

    Object.keys(formValues).map((key) => {
      let value = formValues[key];
      switch (key) {
        case 'fullName':
        case 'position':
          if (!isAlpha(value)) {
            error[key] = '*Only allowed alphabets';
            isValid = false;
          }
          break;
        case 'email':
          if (!isEmail(value)) {
            error.email = '*Invalid email';
            isValid = false;
          }
          break;
        case 'phone':
          if (!isMobilePhone(value, ['en-AU'])) {
            error.phone = '*Invalid phone number';
            isValid = false;
          }
          break;
      }
    });
    setIsValidForm(isValid);
    console.log({ isValidForm: isValid, errorValues: error });
    return { isValidForm: isValid, errorValues: error };
  };

  const handleChange = (obj: { value: string; field: string }) => {
    const { value, field } = obj;

    let error = { ...errorValues };
    validateForm({ ...formValues, [field]: value });
    if (field != 'message' && isEmpty(value)) {
      error[field] = '*Mandatory field';
      settingFormValue(obj);
      setErrorValues(error);
      return;
    }
    if (isUrlPresent(value)) {
      error[field] = '*URL not allowed';
      settingFormValue(obj);
      setErrorValues(error);
      return;
    }
    error[field] = '';

    switch (field) {
      case 'fullName':
      case 'position':
        error[field] = !isAlpha(value) ? '*Only allowed alphabets' : '';
        break;
      case 'email':
        error.email = !isEmail(value) ? '*Invalid email' : '';
        break;
      case 'phone':
        error.phone = !isMobilePhone(value, ['en-AU']) ? '*Invalid phone number' : '';
        break;
    }

    setErrorValues(error);
    settingFormValue(obj);
  };

  return (
    <form onSubmit={onSubmit}>
      <Grid container spacing={3} marginTop="48px">
        <Grid item xs={12} md={6}>
          <CustomInput
            placeholder="First Name*"
            onChange={(e) => handleChange({ value: e.target.value, field: 'fullName' })}
            value={fullName}
            style={{ width: '100%' }}
          />
          <ErrorMessage theme={theme} padding="10px 18px 0px">
            {errorValues.fullName}
          </ErrorMessage>
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomInput
            placeholder="Email*"
            value={email}
            onChange={(e) => handleChange({ value: e.target.value, field: 'email' })}
            style={{ width: '100%' }}
          />
          <ErrorMessage theme={theme} padding="10px 18px 0px">
            {errorValues.email}
          </ErrorMessage>
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomInput
            placeholder="Phone*"
            value={phone}
            onChange={(e) => handleChange({ value: e.target.value, field: 'phone' })}
            style={{ width: '100%' }}
          />
          <ErrorMessage theme={theme} padding="10px 18px 0px">
            {errorValues.phone}
          </ErrorMessage>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledSelectElement
            onChange={(e) => handleChange({ value: e.target.value, field: 'enquiryType' })}
            value={enquiryType}
          >
            <StyledSelectOptionElement selected value="">
              I am looking for* ↓
            </StyledSelectOptionElement>
            <StyledSelectOptionElement value="general">General </StyledSelectOptionElement>
            <StyledSelectOptionElement value="menuItem2">Menu Item 2 </StyledSelectOptionElement>
            <StyledSelectOptionElement value="menuItem3">Menu Item 3 </StyledSelectOptionElement>
          </StyledSelectElement>
          {/* <CustomInput
            placeholder="I am looking for*	↓"
            value={position}
            onChange={(e) => handleChange({ value: e.target.value, field: 'position' })}
            style={{ width: '100%' }}
          /> */}
          <ErrorMessage theme={theme} padding="10px 18px 0px">
            {errorValues.enquiryType}
          </ErrorMessage>
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <CustomInput
            placeholder="I am looking for*	↓"
            value={position}
            onChange={(e) => handleChange({ value: e.target.value, field: 'position' })}
            style={{ width: '100%' }}
          />
          <ErrorMessage theme={theme} padding='10px 18px 0px'>{errorValues.position}</ErrorMessage>
        </Grid> */}
        {/* <FormControl sx={{ m: 1, minWidth: 120 }}>
          <Select
            value={age}
            onChange={handleChangeLookingFor}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
        </FormControl> */}
        <Grid item xs={12} md={12}>
          <CustomTextArea
            placeholder="Message"
            value={message}
            onChange={(e) => handleChange({ value: e.target.value, field: 'message' })}
            minRows={12}
            maxRows={13}
            style={{ width: '100%' }}
          />
          <ErrorMessage theme={theme} padding="10px 18px 0px">
            {errorValues.message}
          </ErrorMessage>
        </Grid>
      </Grid>
      <Box textAlign="center" marginTop="28px">
        <PrimaryButton type="submit" variant="contained" disabled={!isValidForm || isSubmitting}>
          Submit
        </PrimaryButton>
      </Box>
      <Box textAlign="center" marginTop="60px" paddingBottom="52px">
        <Typography padding={'10px 18px 0px'} color={CAPTIONS_TEXT_COLOR}>
          By submitting this enquiry, I agree to Listing Loop’s <u>Terms & Conditions</u>,
          <u>Privacy Policy </u>and <u>Collection Statement</u>.
        </Typography>
      </Box>
    </form>
  );
}
