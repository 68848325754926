import React from 'react';
import { IconSvg } from './icon-props';

export const IconDecorativeSvg = (props: React.CSSProperties) => {
  const { height, width, color } = props;
  return (
    <IconSvg
      color={color || '#00a6dd'}
      height={height || '56.627'}
      viewBox="0 0 56.627 60.971"
      width={width || '60.971'}
      fill={props.fill || '#00a6dd'}
      style={{ ...props }}
    >
      <path
        id="Path_623"
        data-name="Path 623"
        d="M1041.071,6503.669c-1.058,5.108-2.1,10.2-3.134,15.313a4.3,4.3,0,0,0,.468,3.5c.578.957,2.076,1.33,3.631.8,1.275-.431,1.206-1.022,1.121-1.747a3.565,3.565,0,0,1-.021-.95c.259-1.754.627-3.491,1-5.228.111-.521.222-1.043.329-1.565.452-2.21.857-3.925,1.3-5.8h0c.223-.949.456-1.938.71-3.052a2.118,2.118,0,0,0-.18-1.22c-.035-.081-.064-.167-.093-.255a1.36,1.36,0,0,0-.477-.757l-.04-.029h0a.63.63,0,0,0-.169-.1,6.372,6.372,0,0,0-3.113-.081A1.6,1.6,0,0,0,1041.071,6503.669Zm-8.028,41.635q-1.341,6.961-2.688,13.959a3.65,3.65,0,0,0,.539,3.148c.592.864,2.1,1.163,3.651.672,1.269-.4,1.183-.932,1.076-1.589a2.965,2.965,0,0,1-.05-.873c.208-1.6.524-3.185.842-4.769h0c.1-.475.19-.951.282-1.427.371-1.936.718-3.452,1.09-5.081.213-.928.433-1.893.671-2.993a1.794,1.794,0,0,0-.217-1.092c-.028-.063-.052-.129-.076-.2h0a1.172,1.172,0,0,0-.51-.7.784.784,0,0,0-.211-.107,7.032,7.032,0,0,0-3.1-.023C1033.473,6544.44,1033.124,6544.881,1033.043,6545.3Zm27.608-8.079c-1.2.085-2.409.217-3.613.349a47.19,47.19,0,0,1-7.069.421,2.131,2.131,0,0,1-.926-.27l-.116-.06h0c-.51-.26-1.031-.526-1.054-2.272-.029-1.4.161-1.406.533-1.415a1.265,1.265,0,0,0,.483-.082,5.911,5.911,0,0,1,2.786-.433c.282.012.571.023.894.011,1.682-.047,2.512-.02,3.593.015h0c.582.02,1.236.041,2.135.055a49.5,49.5,0,0,1,6.024.378c.811.12.687.644.517,1.362a6.978,6.978,0,0,0-.157.813c-.108.988-.479,1.065-.781,1.059A25.276,25.276,0,0,0,1060.651,6537.225Zm-44.41-5.463c1.182.354,2.364.707,3.523,1.107a25.188,25.188,0,0,1,2.965,1.214c.285.118.65.21,1.14-.665a7.014,7.014,0,0,1,.468-.686c.443-.593.765-1.024.056-1.452a53.987,53.987,0,0,0-5.4-2.692c-.879-.383-1.5-.667-2.048-.922-.934-.43-1.682-.775-3.2-1.368-.279-.111-.522-.228-.76-.341a5.973,5.973,0,0,0-2.778-.712,1.322,1.322,0,0,1-.47-.124c-.348-.146-.528-.221-1.056,1.091-.643,1.614-.263,2.068.1,2.5l.081.1a2.4,2.4,0,0,0,.763.6A46.5,46.5,0,0,0,1016.241,6531.762Z"
        transform="translate(-1008.336 -6502.348)"
        fill="currentColor"
        fillRule="evenodd"
      />
    </IconSvg>
  );
};
