import React from "react"
import { Router } from "@reach/router"
import AgentDetails from "../components/agent-details"


const Agents = () => {
  return (
      <Router >
        <AgentDetails path="agents/agent-details" />
      </Router>
  )
}

export default Agents