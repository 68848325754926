import React from 'react';
import { IconSvg } from '../icon-props';

export const IconFacebookWithOuterBox = (props: React.CSSProperties) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15.48" height="15.48" viewBox="0 0 15.48 15.48">
      <path
        id="Path_348"
        data-name="Path 348"
        d="M3930.657,11591.125a1.9,1.9,0,0,1,1.368.558,1.811,1.811,0,0,1,.576,1.351v11.627a1.968,1.968,0,0,1-1.944,1.945h-2.916v-5.58h2.052v-2.412h-2.052v-1.261c0-.36.168-.539.5-.539h1.548v-2.737h-1.728a2.92,2.92,0,0,0-2.232.973,3.368,3.368,0,0,0-.9,2.376v1.188h-1.872v2.412h1.872v5.58h-5.868a1.967,1.967,0,0,1-1.944-1.945v-11.627a1.811,1.811,0,0,1,.576-1.351,1.9,1.9,0,0,1,1.368-.558Z"
        transform="translate(-3917.121 -11591.125)"
        fill="#061e3b"
      />
    </svg>
  );
};
