import { Chip, Grid, Theme, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { Link } from 'gatsby';
import React from 'react';
import { IconDecorativeSvg } from '../assets/icons/decorative-svg';
import { DisorderLine } from '../assets/icons/pattern/disorder-line';
import { IconFacebookWithOuterBox } from '../assets/icons/social/facebookWithOuterBox';
import { IconLinkedIn } from '../assets/icons/social/linkedin';
import { IconTwitter } from '../assets/icons/social/twitter';
import { CAPTIONS_TEXT_COLOR, LIGHT_GREY_COLOR, LL_BACKGROUND_COLOR } from '../colors';
import { DecorativeHeading } from './decorative-heading';
import ContactAgentForm from './forms/contact-agent-form';
import Layout from './layout';
import { Absolute } from './primitives/absolute';
import { PrimaryButton } from './primitives/primary-button';
import { Width80Box } from './primitives/Width80Box';
import {RouteComponentProps} from '@reach/router'

interface props extends RouteComponentProps{}

const AgentImage =
  'https://camo.githubusercontent.com/a94717d64129c017b934e8d300f735d3a2f2a34b31e52ac748e0d132127a72b3/68747470733a2f2f6a656e737365676572732e636f6d2f7374617469632f6d656469612f6167656e742e706e67';

const AgentDetails = (props: RouteComponentProps) => {
  const theme: Theme = useTheme();
  const areasOfService = ['Mornington', 'Mt Martha', 'Mt Eliza', 'Somerville', 'Tyabb'];
  const aboutAgent = [
    '1st para Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh\
     euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim\
     veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea\
     commodo consequat.',
    '2nd para Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh\
     euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim\
     veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea\
     commodo consequat.',
    '3rd para Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh\
     euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim\
     veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea\
     commodo consequat.'
  ];
  return (
    <Box py={{ xs: '50px', md: '100px' }}>
      <Width80Box
        marginTop="80px"
        marginBottom="100px"
        sx={{
          [theme.breakpoints.down(1025)]: {
            paddingX: '20px'
          }
        }}
      >
        <Grid container>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                marginLeft: '15%',
                [theme.breakpoints.down('md')]: {
                  marginBottom: '16px',
                  marginX: 'auto'
                }
              }}
              width="fit-content"
              position="relative"
            >
              <Absolute right="5%" height="15%" textAlign="right" top="0px">
                <IconDecorativeSvg height="100%" />
              </Absolute>
              <img
                alt="Agent Image"
                src={AgentImage}
                style={{
                  borderRadius: '50%',
                  maxHeight: '380px',
                  maxWidth: '380px',
                  width: '100%'
                }}
              />
              <Absolute left="5%" height="15%" textAlign="left" bottom="15px">
                <IconDecorativeSvg height="100%" />
              </Absolute>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              [theme.breakpoints.down('md')]: {
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column'
              }
            }}
          >
            <Chip
              label="RayWhite"
              sx={{
                background: 'yellow',
                color: theme.palette.secondary.main,
                fontSize: '14px',
                fontWeight: 500
              }}
            />
            <Typography
              color="primary.main"
              sx={{
                fontFamily: 'Tropiline',
                fontSize: '60px',
                marginTop: '32px',
                [theme.breakpoints.down('md')]: {
                  fontSize: '30px'
                }
              }}
            >
              Lilly Williams
            </Typography>
            <Typography color={CAPTIONS_TEXT_COLOR} fontSize="19px" fontWeight={500}>
              Sales Consultant
            </Typography>
            <Typography color="secondary.main" fontSize="19px" fontWeight={500}>
              Ray White Mornington
            </Typography>
            <PrimaryButton
              sx={{
                marginTop: '36px',
                minWidth: '204px',
                textTransform: 'none',
                whiteSpace: 'nowrap'
              }}
              variant="contained"
            >
              Contact Liily
            </PrimaryButton>
          </Grid>
        </Grid>
        <Grid container marginTop="80px">
          <Grid item xs={12} md={6}>
            <Typography color={LIGHT_GREY_COLOR} fontFamily="Tropiline" variant="h6">
              Areas of Service
            </Typography>
            <Box
              display="flex"
              sx={{
                marginBottom: '64px',
                overflowX: 'auto',
                [theme.breakpoints.down('md')]: {
                  marginBottom: '32px'
                }
              }}
            >
              {areasOfService.map((item) => (
                <Typography
                  color="secondary.main"
                  fontWeight={500}
                  sx={{
                    borderBottom: '1px solid ' + theme.palette.primary.main,
                    fontSize: '19px',
                    marginRight: '16px',
                    whiteSpace: 'nowrap'
                  }}
                >
                  {item}
                </Typography>
              ))}
            </Box>
            <Typography color={LIGHT_GREY_COLOR} fontFamily="Tropiline" variant="h6">
              Contact Information
            </Typography>
            <Typography color="secondary.main" variant="body1">
              Address
            </Typography>
            <Typography color="secondary.main" fontWeight={500} sx={{ fontSize: '19px' }}>
              1 Main Street, Mornington, VIC, 3931
            </Typography>
            <Typography color="secondary.main" variant="body1">
              Website
            </Typography>
            <Typography color="secondary.main" fontWeight={500} sx={{ fontSize: '19px' }}>
              raywhitemornington.com.au
            </Typography>
            <Typography color="secondary.main" variant="body1">
              Social
            </Typography>
            <Box display="flex">
              <Typography
                color="secondary.main"
                fontWeight={500}
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  borderBottom: '1px solid ' + theme.palette.primary.main,
                  fontSize: '19px',
                  marginRight: '16px'
                }}
              >
                <IconFacebookWithOuterBox />
                <span
                  style={{
                    marginRight: '8px'
                  }}
                />
                Facebook
              </Typography>
              <Typography
                color="secondary.main"
                fontWeight={500}
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  borderBottom: '1px solid ' + theme.palette.primary.main,
                  fontSize: '19px',
                  marginRight: '16px'
                }}
              >
                <IconTwitter color={theme.palette.secondary.main} width="16.6px" />
                <span
                  style={{
                    marginRight: '8px'
                  }}
                />
                Twitter
              </Typography>
              <Typography
                color="secondary.main"
                fontWeight={500}
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  borderBottom: '1px solid ' + theme.palette.primary.main,
                  fontSize: '19px'
                }}
              >
                <IconLinkedIn color={theme.palette.secondary.main} width="16.2px" />
                <span
                  style={{
                    marginRight: '8px'
                  }}
                />
                LinkdIn
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              color={LIGHT_GREY_COLOR}
              fontFamily="Tropiline"
              variant="h6"
              sx={{
                marginBottom: '32px',
                [theme.breakpoints.down('md')]: {
                  marginTop: '32px',
                  marginBottom: '16px'
                }
              }}
            >
              About Lilly
            </Typography>
            {aboutAgent.map((para) => (
              <Typography color="secondary.main" marginBottom="16px" variant="body1">
                {para}
              </Typography>
            ))}
            <Typography
              color="primary.main"
              sx={{
                marginTop: '32px',
                textAlign: 'left',
                [theme.breakpoints.down('md')]: {
                  textAlign: 'center'
                }
              }}
            >
              <Link
                to={'#'}
                style={{
                  color: 'inherit',
                  fontFamily: 'Rubik',
                  fontSize: '16px',
                  fontWeight: 500,
                  textDecoration: 'none',
                  textTransform: 'none'
                }}
              >
                Contact Lilly
                <span
                  style={{
                    fontFamily: 'Inter',
                    marginLeft: '8px'
                  }}
                >
                  ↓
                </span>
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Width80Box>
      <DisorderLine marginBottom="-90px" width="100%" />
      <Box
        sx={{
          background: LL_BACKGROUND_COLOR,
          width: '100%'
        }}
      >
        <Width80Box
          paddingTop="100px"
          sx={{
            [theme.breakpoints.down(1025)]: {
              paddingX: '20px'
            }
          }}
        >
          <DecorativeHeading text="Contact Lily Williams" />
          <ContactAgentForm theme={theme} />
        </Width80Box>
        <Box bgcolor={LL_BACKGROUND_COLOR} width="100%">
          <Box position="relative">
            <Absolute
              height="250px"
              width="100%"
              zIndex={-1}
              bgcolor={LL_BACKGROUND_COLOR}
            ></Absolute>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

AgentDetails.Layout = Layout;
AgentDetails.isNavbarBackgroundEmpty = true;

export default AgentDetails;
